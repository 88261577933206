.App {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url("banner.jpg_large");
  background-size: cover;
  /* background-repeat: repeat; */
  background-repeat: repeat-y;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.App-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1rem;
  max-width: 1276px;
  margin: 0 auto;
}

.App-navbar__right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.box {
  margin: 0;
  padding: 0.5rem;
  /* background-color: rgb(91 100 102 / 75%); */
}

h1.supply {
  margin: 0;
}

.mint {
  font-size: 2rem !important;
}

.error-message {
  font-size: 1rem;
  color: #f01d6c;
  background: black;
  padding: 1rem;
  border-radius: 4px;
  word-wrap: break-word;
  max-width: 400px;
}

.button {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  padding: 0.5rem 4rem;
  border: 3px solid white;
  background-color: #5b6466;
  color: white;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  text-transform: lowercase;
  font-family: "Old English", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.button:hover {
  background-color: #303f43;
}

.button.mints {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.button.mints .title {
  font-size: 2rem;
}

button:disabled {
  background-color: #2f2a34;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #2f2a34;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.limited-edition {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.question-mark {
  font-size: 4rem !important;
  color: #f01d6c;
  margin: 0;
}

.App-navbar h1 {
  font-size: 2rem;
}

.App-link {
  color: #61dafb;
}

.App-main {
  display: grid;
  width: 100%;
  max-width: 1500px;
  height: 100vh;
}

@media (max-width: 1276px) {
  .App {
    height: 100% !important;
  }
  .App-main {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .App-navbar {
    justify-content: center !important;
  }
  .grid {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
  }
  .grid-item {
    justify-content: center !important;
  }
  .grid-item.menu {
    padding-top: 0 !important;
  }
  .grid-item img {
    width: calc(100% - 40px) !important;
  }
}

.grid {
  display: grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 3rem;
  grid-auto-rows: auto;
}

.grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
}

.grid-item.flex-end {
  justify-content: flex-end;
}

.grid-item.menu {
  align-items: flex-start;
  padding-top: 5rem;
}

.grid-item img {
  width: 100%;
  max-width: 500px;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
}

.grid-item.middle-image img {
  display: flex;
  flex-direction: column;
  width: 33% !important;
}

.middle-image-text {
  display: flex;
  flex-direction: column;
  color: #f01d6c;
  font-size: 2rem;
  font-weight: bold;
}

.middle-image_text_below {
  color: white;
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 30%;
  background-color: #2f2a34;
  color: #fff;
  padding: 3rem !important;
  z-index: 20;
  border-radius: 1rem;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
  overflow: hidden;
  outline: 0;
  background: black;
  opacity: 0.5;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ddd;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.slider::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ddd;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.mintButton {
  display: flex;
  gap: 0.5rem;
}

.mintButton .mints {
  width: 150%;
}

.input-number {
  display: flex;
}

.input-number__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  /* background: transparent; */
  /* border: 3px solid rgb(0 0 0 / 30%); */
}

/* .input-number__button:hover { */
/*   opacity: 100%; */
/* } */

.input-number__input {
  background-color: transparent;
  width: 100%;
  font-family: "Old English", sans-serif;
  font-size: 2rem;
  text-align: center;
  border: 3px solid white;
  border-radius: 5px;
  color: white;
}
